.pt-modal {
   .modal-body {
      font-size: 130%;
      padding: 50px;
   }

   h3 {
     color: #999;
   }
}

.modal-dialog {
   margin-bottom: 3rem;
}
