/**
* font-face has been moved to /public/index.css to ensure compatibility with the live server
*/

// @font-face {
//   font-family: 'icomoon';
//   src:  url('./fonts/icomoon.eot?3unqko');
//   src:  url('./fonts/icomoon.eot?3unqko#iefix') format('embedded-opentype'),
//     url('./fonts/icomoon.woff2?3unqko') format('woff2'),
//     url('./fonts/icomoon.ttf?3unqko') format('truetype'),
//     url('./fonts/icomoon.woff?3unqko') format('woff'),
//     url('./fonts/icomoon.svg?3unqko#icomoon') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-acc {
    display: inline-block;
    margin-right: 6px;
}
.ico-acc-basic {
  display: inline-block;
}
.ico-acc-button {
  display: inline-block;
  margin-right: 6px;
  position: absolute;
  right: 20px;
}
.ico-acc-list {
  display: inline-block;
  margin-right: 6px;
  color: #333;
}
.ico-acc-list-head {
  display: inline-block;
  color: #333;
  text-align: center;

}
.ico-acc-list-status{
  display: inline-block;
  font-size: 150% !important;
  text-shadow: #fff 1px 1px, #fff 0 1px, #fff -1px 1px, #fff -1px 0, #fff -1px -1px, #fff 0 -1px, #fff 1px -1px, #fff 1px 0;
}
.ico-event {
  font-size: 110%;
  margin-top: 2px;
  margin-left: 1px;
  margin-right: 3px;
}

.ico-large {
  font-size: 130% !important;
}
.ico-at-header {
  // font-size: 2.4rem !important;
  @include font-size(2.1rem);
}
.ico-multi-mode {
  font-size: 90%;
}
.ico-lplus {
  font-size: 300%;
  color: #666 ;
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.ico-lmed{
  font-size: 100%;
  color: #333 ;
  padding-left: 0px !important;
  margin-left: 0px !important;
}
.ico-xl-grey {
  font-size: 500%;
  color: #ccc ;
}
.ico-xl {
  font-size: 500% !important;
}
.ico-modals-warning {
  color:  $glow;
}
.ico-modals-warning2 {
  color:  $glow;
  font-size: 120%;
  font-weight: 700;

}

.ico-modals-danger {
  color:  $red;
}
.ico-modals-confirm {
  color:  $green;
}
.icon-grey{
 color: #666;
}

 .ico-merged{
 margin-top: 2px;
 margin-left: 1px;
 margin-right: 0px !important;
}
.ico-ds-merged{
  color: #999999;
  margin-top: 2px;
  margin-left: 1px;
  margin-right: 3px !important;
}

.ico-acc-datepicker {
  display: inline-block;
  margin-right: 6px;
  font-size: 1rem;
  color: rgb(73, 73, 73);
  position: relative;
  top: 4px;

}

.ico-contact {
  color: rgb(182, 182, 182);
}
.ico-contact-alt {
  color: rgb(92, 92, 92) !important;
}
.ico-contact-alt3 {
  color: rgb(158, 158, 158) !important;
  font-size: 0.6rem;
}
.ico-contact-alt2 {
  color: $blue !important;
  font-size: 1.1rem;
  margin-right:0;
  padding-top:0;
  margin-top:0;
}
.pt-icon-sort {
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
}
.pt-read-only-mode {
  &span{
  background-color: blue !important;
  color: #fff;
  display: inline-block;
  text-shadow: #fff 1px 1px, #fff 0 1px, #fff -1px 1px, #fff -1px 0, #fff -1px -1px, #fff 0 -1px, #fff 1px -1px, #fff 1px 0;
}
}

/* Icomoon icons */
.icon-pt-online-workshop:before {
  content: "\e917";
}
.icon-pt-webinar:before {
  content: "\e918";
}
.icon-pt-d-arrow-start:before {
  content: "\e915";
}
.icon-pt-d-arrow-end:before {
  content: "\e916";
}
.icon-pt-invoice:before {
  content: "\e919";
}
.icon-pt-invoice1:before {
  content: "\e91a";
}
.icon-pt-stack2:before {
  content: "\e914";
}
.icon-pt-user:before {
  content: "\e912";
}
.icon-pt-multi2:before {
  content: "\e90f";
}
.icon-pt-multi:before {
  content: "\e908";
}
.icon-pt-single1:before {
  content: "\e90a";
}
.icon-pt-single2:before {
  content: "\e90e";
}
.icon-pt-ungroup:before {
  content: "\e906";
}
.icon-pt-group2:before {
  content: "\e907";
}
.icon-pt-group:before {
  content: "\e905";
}
.icon-pt-no-people:before {
  content: "\e904";
}
.icon-pt-d-arrow-right:before {
  content: "\e902";
}
.icon-pt-d-arrow-left:before {
  content: "\e903";
}
.icon-calendar-pencil:before {
  content: "\e901";
}
.icon-org-pt:before {
  content: "\e900";
}
.icon-asterisk:before {
  content: "\f069";
}
.icon-plus:before {
  content: "\f067";
}
.icon-question:before {
  content: "\f128";
}
.icon-star:before {
  content: "\f005";
}
.icon-user:before {
  content: "\f007";
}
.icon-th-list:before {
  content: "\f00b";
}
.icon-tags:before {
  content: "\f02c";
}
.icon-book:before {
  content: "\f02d";
}
.icon-pencil:before {
  content: "\f040";
}
.icon-edit:before {
  content: "\f044";
}
.icon-pencil-square-o:before {
  content: "\f044";
}
.icon-check-circle:before {
  content: "\f058";
}
.icon-question-circle:before {
  content: "\f059";
}
.icon-ban:before {
  content: "\f05e";
}
.icon-exclamation-circle:before {
  content: "\f06a";
}
.icon-eye1:before {
  content: "\f06e";
}
.icon-eye-slash:before {
  content: "\f070";
}
.icon-calendar1:before {
  content: "\f073";
}
.icon-folder-open1:before {
  content: "\f07c";
}
.icon-cogs1:before {
  content: "\f085";
}
.icon-gears:before {
  content: "\f085";
}
.icon-sign-out:before {
  content: "\f08b";
}
.icon-hand-o-right:before {
  content: "\f0a4";
}
.icon-wrench:before {
  content: "\f0ad";
}
.icon-filter:before {
  content: "\f0b0";
}
.icon-group:before {
  content: "\f0c0";
}
.icon-users:before {
  content: "\f0c0";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-navicon:before {
  content: "\f0c9";
}
.icon-reorder:before {
  content: "\f0c9";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-up:before {
  content: "\f0d8";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-sort:before {
  content: "\f0dc";
}
.icon-unsorted:before {
  content: "\f0dc";
}
.icon-rotate-left:before {
  content: "\f0e2";
}
.icon-undo:before {
  content: "\f0e2";
}
.icon-clipboard:before {
  content: "\f0ea";
}
.icon-paste:before {
  content: "\f0ea";
}
.icon-lightbulb-o:before {
  content: "\f0eb";
}
.icon-plus-square:before {
  content: "\f0fe";
}
.icon-spinner:before {
  content: "\f110";
}
.icon-folder-open-o:before {
  content: "\f115";
}
.icon-flag-checkered:before {
  content: "\f11e";
}
.icon-info:before {
  content: "\f129";
}
.icon-graduation-cap:before {
  content: "\f19d";
}
.icon-mortar-board:before {
  content: "\f19d";
}
.icon-child:before {
  content: "\f1ae";
}
.icon-sliders:before {
  content: "\f1de";
}
.icon-slideshare:before {
  content: "\f1e7";
}
.icon-calculator:before {
  content: "\f1ec";
}
.icon-trash:before {
  content: "\f1f8";
}
.icon-toggle-off:before {
  content: "\f204";
}
.icon-toggle-on:before {
  content: "\f205";
}
.icon-user-plus1:before {
  content: "\f234";
}
.icon-user-times:before {
  content: "\f235";
}
.icon-bed1:before {
  content: "\f236";
}
.icon-hotel:before {
  content: "\f236";
}
.icon-hand-paper-o:before {
  content: "\f256";
}
.icon-hand-stop-o:before {
  content: "\f256";
}
.icon-map-signs:before {
  content: "\f277";
}
.icon-percent:before {
  content: "\f295";
}
.icon-universal-access:before {
  content: "\f29a";
}
.icon-low-vision:before {
  content: "\f2a8";
}
.icon-address-card:before {
  content: "\f2bb";
}
.icon-vcard1:before {
  content: "\f2bb";
}
.icon-user-circle:before {
  content: "\f2bd";
}
.icon-newspaper:before {
  content: "\e90b";
}
.icon-design:before {
  content: "\e90d";
}
.icon-pencil4:before {
  content: "\e911";
}
.icon-toggle:before {
  content: "\e92d";
}
.icon-camera:before {
  content: "\e944";
}
.icon-music5:before {
  content: "\e94e";
}
.icon-bullhorn:before {
  content: "\e979";
}
.icon-podcast:before {
  content: "\e980";
}
.icon-books:before {
  content: "\e995";
}
.icon-archive:before {
  content: "\e996";
}
.icon-reading:before {
  content: "\e997";
}
.icon-library2:before {
  content: "\e999";
}
.icon-graduation:before {
  content: "\e99a";
}
.icon-file-empty:before {
  content: "\e99d";
}
.icon-file-empty2:before {
  content: "\e99e";
}
.icon-file-text2:before {
  content: "\e9ad";
}
.icon-file-text3:before {
  content: "\e9ae";
}
.icon-paste2:before {
  content: "\e9ca";
}
.icon-stack3:before {
  content: "\e9cf";
}
.icon-folder-remove:before {
  content: "\e9da";
}
.icon-ticket:before {
  content: "\e9f9";
}
.icon-theater:before {
  content: "\e9fb";
}
.icon-coin-pound:before {
  content: "\ea0d";
}
.icon-lifebuoy:before {
  content: "\ea1b";
}
.icon-address-book2:before {
  content: "\ea2c";
}
.icon-at-sign:before {
  content: "\ea2f";
}
.icon-location:before {
  content: "\ea38";
}
.icon-clock:before {
  content: "\ea4d";
}
.icon-stopwatch:before {
  content: "\ea5e";
}
.icon-calendar:before {
  content: "\ea5f";
}
.icon-calendar5:before {
  content: "\ea63";
}
.icon-tv:before {
  content: "\ea7c";
}
.icon-floppy-disk:before {
  content: "\ea89";
}
.icon-bubbles2:before {
  content: "\eac6";
}
.icon-bubble-smiley:before {
  content: "\eae3";
}
.icon-user-plus:before {
  content: "\eaf9";
}
.icon-user-minus:before {
  content: "\eafa";
}
.icon-user-cancel:before {
  content: "\eafb";
}
.icon-user-block:before {
  content: "\eafc";
}
.icon-user-check:before {
  content: "\eafe";
}
.icon-user6:before {
  content: "\eb0b";
}
.icon-collaboration:before {
  content: "\eb10";
}
.icon-hat:before {
  content: "\eb12";
}
.icon-tshirt:before {
  content: "\eb16";
}
.icon-hour-glass:before {
  content: "\eb1f";
}
.icon-spinner3:before {
  content: "\eb24";
}
.icon-search:before {
  content: "\eb30";
}
.icon-lock4:before {
  content: "\eb53";
}
.icon-traffic-cone:before {
  content: "\eb7e";
}
.icon-pie-chart7:before {
  content: "\eb86";
}
.icon-crown:before {
  content: "\eba5";
}
.icon-balloon:before {
  content: "\ebae";
}
.icon-glass:before {
  content: "\ebb2";
}
.icon-leaf:before {
  content: "\ebcd";
}
.icon-footprint:before {
  content: "\ebd9";
}
.icon-flower:before {
  content: "\ebdb";
}
.icon-airplane2:before {
  content: "\ec06";
}
.icon-paperplane:before {
  content: "\ec09";
}
.icon-car2:before {
  content: "\ec0c";
}
.icon-truck:before {
  content: "\ec0f";
}
.icon-train2:before {
  content: "\ec13";
}
.icon-ship:before {
  content: "\ec14";
}
.icon-accessibility:before {
  content: "\ec28";
}
.icon-finish:before {
  content: "\ec44";
}
.icon-clipboard6:before {
  content: "\ec55";
}
.icon-more2:before {
  content: "\ec5c";
}
.icon-grid:before {
  content: "\ec5d";
}
.icon-menu:before {
  content: "\ec6a";
}
.icon-sphere:before {
  content: "\ec91";
}
.icon-link4:before {
  content: "\ec9f";
}
.icon-unlink4:before {
  content: "\eca0";
}
.icon-attachment:before {
  content: "\ecac";
}
.icon-eye:before {
  content: "\ecae";
}
.icon-happy2:before {
  content: "\ed03";
}
.icon-select2:before {
  content: "\ed2b";
}
.icon-warning:before {
  content: "\ed4f";
}
.icon-warning2:before {
  content: "\ed50";
}
.icon-notification:before {
  content: "\ed51";
}
.icon-plus2:before {
  content: "\ed5b";
}
.icon-info1:before {
  content: "\ed63";
}
.icon-info2:before {
  content: "\ed64";
}
.icon-cancel-circle:before {
  content: "\ed65";
}
.icon-cancel-circle2:before {
  content: "\ed66";
}
.icon-blocked:before {
  content: "\ed67";
}
.icon-cross:before {
  content: "\ed6c";
}
.icon-enter3:before {
  content: "\ed7a";
}
.icon-exit3:before {
  content: "\ed7b";
}
.icon-stop2:before {
  content: "\ed87";
}
.icon-arrow-up:before {
  content: "\edb7";
}
.icon-arrow-up2:before {
  content: "\edb8";
}
.icon-arrow-right:before {
  content: "\edbb";
}
.icon-arrow-right2:before {
  content: "\edbc";
}
.icon-arrow-right3:before {
  content: "\edbd";
}
.icon-arrow-right4:before {
  content: "\edbe";
}
.icon-arrow-down:before {
  content: "\edbf";
}
.icon-arrow-down2:before {
  content: "\edc0";
}
.icon-arrow-left:before {
  content: "\edc3";
}
.icon-arrow-left2:before {
  content: "\edc4";
}
.icon-arrow-left3:before {
  content: "\edc5";
}
.icon-arrow-left4:before {
  content: "\edc6";
}
.icon-arrow-up5:before {
  content: "\edc7";
}
.icon-arrow-right5:before {
  content: "\edc8";
}
.icon-arrow-down5:before {
  content: "\edc9";
}
.icon-arrow-left5:before {
  content: "\edca";
}
.icon-arrow-down16:before {
  content: "\ee39";
}
.icon-menu-open:before {
  content: "\ee3b";
}
.icon-sort2:before {
  content: "\ee49";
}
.icon-checkmark-circle:before {
  content: "\ee76";
}
.icon-ungroup:before {
  content: "\ee7e";
}
.icon-share3:before {
  content: "\eee0";
}
.icon-file-pdf:before {
  content: "\ef3e";
}
