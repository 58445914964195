.admin-category-tree-container {
    height: 120vh;
    // overflow-y: scroll;
    position: relative;
    margin-bottom: 60px;
}

.admin-category-tree {
    overflow-x: hidden;
    height: 100%;
}

.pt-editor-tab-content {

    margin-top: 10px;

}
.pt-billing {
    background-color: rgb(187, 162, 202) !important;
    border-color: rgb(141, 107, 160);
    .card-header{
        background-color: rgb(187, 162, 202) !important;
        border-color: rgb(141, 107, 160);
    }
    .card-footer{
        background-color: rgb(242, 238, 245) !important;
        border-color: rgb(141, 107, 160);
    }

}
.pt-validated{
    color: $green ;
}

.pt-staff-col {

    background-color: rgb(161, 161, 161) !important;  

}
