.app-loading {
    width: 100%;
    height: 100%;

    h1 {
        color: #999;
        font-size: 90px;
    }
    h5 {
        color: #999;
        font-size: 135%;
    }

    &-text {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        span {
            display: block;
            text-align: center;
            font-weight: bold;
            color: #999;
            margin-top: 10px;
            font-size: 20px;
        }
    }

}

.progress-bg {
    background-color: $pt-progress-bg-light;
}