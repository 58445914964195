// adjusting calendar buttons
.fc-button-primary {
    background-color: #fff !important;
    border-color: #c9c9c9 !important;
    border-radius: 4px;
    color:rgb(112, 112, 112) !important;
    font-size: 100%;
  
  &:focus {
    box-shadow: none !important;
  }
  &:hover{
    color:#fff !important;
    background-image: linear-gradient($blue, $blue);
  }
  &:not(:disabled) {
    &.fc-button-active,
    &:active {
        color: #fff !important;
        background-color: rgb(134, 134, 134) !important;
        border-color: #c9c9c9 !important;
    }
  }
}
.fc-button-primary:disabled {
        background-color: rgb(228, 228, 228) !important;
        opacity: 1.0;
        border-color: #c9c9c9 !important;
        color:rgb(112, 112, 112);
}

// adjusting calendar look
.fc-widget-header {
  border: 0px !important;
  }
  
  .fc th {
  font-size: 140%;
  color: #666;
  font-weight: normal !important;
  border: 0px !important;
  // padding-right: 5px;
  padding-left: 5px;
  text-align: left;
    }
  .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
    // padding-right: 5px;
    padding-left: 5px;
    color: #666;
    float: left !important;
   }

//  adjusting calendar event styles
.fc-event-container {
  a {
    text-decoration: none;
    cursor: pointer;
  }
}
// adjusting calendar title style
.fc-toolbar {
    h2 {
      font-size: 2rem !important;
      font-weight: normal !important;
      display: inline;
    }

    .fc-center {
      text-align: center;
    }

    .fc-right {
      padding-left: 15px;
      text-align: right;
    }

    .fc-left {
      padding-right: 15px;
    }
}

.fc-view-container {
  .fc-event, .fc-event-dot {
      background-color: transparent;
      border-color: transparent;
      font-size: 1rem;
      padding-left: 0px;
      padding-right: 0px;
      margin-bottom: 15px;
      margin-left: 0px;
      margin-right: 0px;
      // to help day view labels
      // width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .booking-event{
      margin: 0;
      padding: 0.3rem;
      padding-bottom: 6px;
      color: #000;
      height: 100%;

      .event-title {
        margin: 0;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
      }
      .event-title2 {
        margin: 0;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        padding: 3px 3px 3px 3px;
        background-color: rgba(255, 255, 255, 0.7);
        font-size: 85%;
        // border: 1px solid #333 !important;
      }


    }
      // style time on single calendar
      .pt-calendar-time {
        margin: 0 !important;
        padding: 0 !important;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 90%;
      }

  // OLD booking event types
  .perf {
    background-color: #88e1fc;
  }
  .wksh {
     background-color: #fca440;
  }
  .wkshe {
    background-color: #fca440;
  }
  .wksht {
    background-color: #fca440;
  }
  .walk {
    background-color: #b9e65f;
  }
  .psd {
    background-color: #61b2ca;
  }
  .tl {
    background-color: #eef116;
  }
  // .spc {
  //   background-color: #464747;
  // }
  // .conf {
  //   background-color: #464747;
  // }



  // old day schedule events pastel colors
  

  // class for blocking events on the merged view
  .blocking-merged {
    @extend .blocking;
    background-color: #fff !important;
    min-height: inherit;
  }

  .non-blocking-merged {
      @extend .blocking;
      background-image: none;
      background-color: #fff !important;
      min-height: inherit;
      border: 0.3rem dashed rgb(133, 133, 133);
  }


  .wrapper {
    margin-top: 0.5rem;
    padding: 0.16rem;


    .project-title {
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
      padding-left: 2px;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.8rem;
      // padding-bottom: 0.2rem;

        h4 {
          font-size: 1.2rem;
        }

    }

    .info-container {
      background: #fff;
      color: #000;
      font-size: 1rem;
      margin-top: 0.3rem;
      border-radius: 4px 4px 0px 0px;
      // padding-top: 0.3rem;
    }

  //    .title-container {
  // // text-decoration: underline;
  //   font-weight: bold;
  //    }

    .glyph-container svg {
      width: 1rem;

    }

    ul.events {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;


      .event-title {
          margin: 0;
          white-space: pre-wrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // padding: 2px;
        }

      > li {
        font-size: 0.8rem;
        margin-top: 0.3rem;
        color: #333;
        padding: 4px;
        font-size: 1rem;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;


        svg {
          margin-left: -1.6rem;
          width: 1.2rem;

        }
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .glyph-container {
    padding-left: 0.2rem;
    white-space: pre-wrap;


    .isvg.loaded {
      &.done {
        fill: green;
      }

      &.todo {
        fill: orange;
      }

      &.na {
        fill: gray;
      }

      &.blocked {
        fill: red;
      }
    }
  }
}

.calendar-wrapper {
  // margin-top: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 60px;

  &.merged-view {
    .fc-timeGridDay-button {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

// a day schedule event exists for a booking on merged view
.ds-event-exists > svg {
  fill: rgb(75, 235, 12);
}

// a day schedule event does not exist for a booking on merged view
.ds-event-absent > svg {
  fill: rgb(168, 168, 168);
}

// change background colour of month view past dates
.fc-other-month{
  background: #efefef !important;
}
// other month events style
.pt-other-month {
  opacity: 0.6;
}

.fc-scroller {
  height: auto !important;
  overflow: initial !important;
}
// making calendar nav buttons same size as other header buttons
.fc-right {
  .fc-button-group {
    font-size: 0.86em;
  }
}
.pt-badge-md{
  font-size: 97%;
  padding:6px 8px 1px 8px !important;
  background: rgb(230, 230, 230) !important;
  font-weight: normal !important;
}
.pt-badge-lg{
  font-size: 97%;
  padding:2px !important;
  border: 1px solid rgb(235, 145, 28) !important;
}
.pt-recurs-single {
  font-size: 95%;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  margin-bottom: 2px !important;
  border: 2px dotted rgb(255, 255, 255) !important;
}
.pt-recurs-multimode {
  font-size: 73%;
  margin-top: 1px !important;
  margin-right: 1px !important;
  padding-top: 1px !important;
  padding-bottom: 2px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  border: 2px dotted  rgb(255, 255, 255) !important;
}
.pt-alert-icon-lg{
  font-size: 120%;
  padding: 2px !important;
  color: $warning !important;
  text-shadow: -2px 1px 0 #f36201, 2px 1px 0 #f36201, 1px 0px 0 #f36201, 0px -3px 0 #f36201;
}

// overiding the now indicator style

.fc-now-indicator {

    border: 3px dashed $glow !important;
}
.fc-ltr .fc-time-grid .fc-now-indicator-arrow {

  left: 0 !important;
  border-width: 8px 0 8px 8px !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}

//  fix the day calendar width issue
.fc-ltr .fc-axis {
  text-align: right;
  width: 10%;
}

// Accommodation events on the time grid
.fc-time-grid-event  {
      height: auto;
      padding-bottom: 15px;

    .accomm {
      height: auto;
      display: flex;
      text-align: left;

      .event-title {
        margin: 0;
        white-space: pre;
        padding-right: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        // text-align: left;
        color: #333;
        flex-shrink: 3;
      }
      .pt-calendar-time  {
        flex-shrink: 0;
      }
      .event-title2 {
        flex-shrink: 0;
      }
    }
  }

  .fc-view-container .fc-time-grid-event-inset {
    box-shadow: none;
    padding-bottom: none !important;
  }

// responsive calendar display

// only display the details of an event on wider screens 
.pt-cal-event-details {
    display: none;
    @media screen and (min-width: 660px) {
        display: block;
    }
}

// display the event times as block on smaller screens
.pt-cal-event-times {
    display: block;
    @media screen and (min-width: 660px) {
        display: inline;
    }
}

.pt-calendar-icons {
  display: block;
  margin-bottom: 4px;
  @media screen and (min-width: 660px) {
    float: right;
    display: inline-block;
  }  
  span {
    vertical-align: middle;
  }
}

// Actouree calendar

.actouree-container {
  .fc-ltr .fc-dayGrid-view .fc-day-top {
    cursor: pointer;
    font-size: 1.2rem;
    height: 40px;

    .fc-day-number {
      color: $blue;

      &:hover {
        color: #0073e6;
        text-decoration: none;
      }
    }
  }

  .fc-view-container {
    .fc-event, .fc-event-dot {
      // margin-bottom: 30px;
    }
  }
}