// colors
$blue: #3399ff;
$pt-blue-light:#d6ebff;
$pt-blue-dark:#1e5085;
$red: #ff004c;
$green:#1cff71;
$orange: #ffd76a;
$orange-lite: #fff2ad;
$light-purple-grey: #806a88;
$glow: #ffb039;
$toolbar_bg2: #e5e5e2;
$toolbar_bg: #eeeeee;
// $toolbar_bg: #ebebeb;
// $toolbar_bg2: #e0dfda;
$pt-info-light: #bdbdbd;
$pt-light-grey: #f0f0f0;
$pt-med-grey: #969696;
$pt-mid-grey: #d8d8d8;
// for actouree display highlight:
$hilite: #faf1e2;
$hilite-duo: #f0dec0;
// $hilite: #fcf8ec;
// $hilite-duo: #f9efd2;
// colours for view data 
$view-data1: #f5ffe2;
$view-data2: #d0ff79;
$pt-dark-grey: #6c757d;
$pt-form-border: #cecece;
$pt-readonly-bg: rgb(251, 255, 191);
// modify bootstraps theme colours

$pt-header: #727272;
$pt-nav: #e5e5e2;

$at-bg-dark: #aa15af;
$at-bg-light: #f7c0ff;
$at-bg-dark-hover: #d01ad6;

$pt-bg-dark: #270336;
$pt-bg-light: #aa15af;
$pt-bg-dark-hover: #3a0e50;

$pt-alt: #791e86;
$pt-alt-text: #cdb8d6;

$pt-progress-bg-light: #aa15af;