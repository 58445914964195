

$production-border: #007896;
$workshop-border: #f36201;
$walkabout-border: #6b9e04;
$post-show-chat-border: #023074;
$talk-border: #a00639;
$showcase-border: #ce0303;

// Production Styles -------------------------------------------------------------------

.prod {
    border-top: 5px solid $production-border;
    background-image: linear-gradient(to bottom right, #13badb, #8be8f8);
    .ico-event {
        color: #fff;
    }
}

.on-prod {
    @extend .prod;
    border-top: none;
    border-right: 5px solid $production-border;
}

.mix-prod {
    @extend .prod;
    border-right: 5px solid $production-border;

}

// Workshop Styles -------------------------------------------------------------------

.work {
    border-top: 5px solid $workshop-border;
    background-image: linear-gradient(to bottom right, #ff951c, #ffe9d0);
    .ico-event {
        color: #fff;
    }
}

.on-work {
    @extend .work;
    border-top: none;
    border-right: 5px solid $workshop-border;
}

.mix-work {
    @extend .work;
    border-right: 5px solid $workshop-border;
}

// Post Show Chat -------------------------------------------------------------------

.psc {
    border-top: 5px solid $post-show-chat-border;
    background-image: linear-gradient(to bottom right, #3766ca, #d1e7ff);
    .ico-event {
        color: #fff;
    }
}

.on-psc {
    @extend .psc;
    border-top: none;
    border-right: 5px solid $post-show-chat-border;
}

.mix-psc {
    @extend .psc;
    border-right: 5px solid $post-show-chat-border;
}


// Talk/Lecture  -------------------------------------------------------------------

.talk {
    border-top: 5px solid $talk-border;
    background-image: linear-gradient(to bottom right, #f13a80, #fff7fa);
    .ico-event {
        color: #fff;
    }
}

.on-talk {
    @extend .talk;
    border-top: none;
    border-right: 5px solid $talk-border;
}

.mix-talk {
    @extend .talk;
    border-right: 5px solid $talk-border;
}

// Showcase ---------------------------------------------------------------------------

.showcase {
    border-top: 5px solid $showcase-border;
    background-image: linear-gradient(to bottom right, #ff4848, #ffe4e4);
    .ico-event {
        color: #fff;
    }
}

.on-showcase {
    @extend .showcase;
    border-top: none;
    border-right: 5px solid $showcase-border;
}


.mix-showcase {
    @extend .showcase;
    border-right: 5px solid $showcase-border;
}


// Walkabout Styles -------------------------------------------------------------------

.walk {
    border-top: 5px solid $walkabout-border;
    background-image: linear-gradient(to bottom right, #95db08, #f3ffdc);
    .ico-event {
        color: #fff;
    }
}
.on-walk {
    @extend .walk;
    border-top: none;
    border-right: 5px solid $walkabout-border;
}


.mix-walk {
    @extend .walk;
    border-right: 5px solid $walkabout-border;
}

.product-not-set {
    border-top: 5px dotted #a1a1a1;
    background-color: #ffffff;
    .ico-event {
        color: rgb(139, 139, 139);
    }
}

.web {
    border-right: 5px solid #bb1e52;
    background-image: linear-gradient(to bottom right, #df2d71, #fff7fa);
    .ico-event {
        color: #fff;
    }
}