.btn-delete-row {
    padding-bottom: 5px !important;
    margin: 0 auto;
}

.btn-link {
    color: $blue !important;

    &.active {
        color: $blue !important;
    }
}

.pt-disabled-button {
    opacity: 0.4 !important;
    outline: none !important;
    
    button {
        outline: none !important;
        cursor: not-allowed !important;
    }
    button:focus {
        outline: none !important;
    }
        
    &:hover {
        outline: none !important;
    }
    &:active {
        outline: none !important;
    }
}

.pt-delete-bin {
    // opacity: 40% !important;
    // outline: none !important;
    
    button {
        outline: none !important;
        cursor: not-allowed !important;
    }
    button:focus {
        outline: none !important;
    }
        
    &:hover {
        outline: none !important;
    }
    &:active {
        outline: none !important;
    }

}
.pt-disabled-bin {
    opacity: 0.3 !important;
    // outline: none !important;
    
    button {
        outline: none !important;
        cursor: not-allowed !important;
    }
    button:focus {
        outline: none !important;
    }
        
    &:hover {
        outline: none !important;
    }
    &:active {
        outline: none !important;
    }
}

