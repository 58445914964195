.editor-header-container {
    // height: 86px;
    box-sizing: border-box;
    // border-bottom: 1px solid rgb(199, 199, 199) !important;
}

.editor-h {
    color: #666666;
    box-sizing: border-box;
    padding: 0 4px 0 6px;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;   
}

.editor-header-subtitle {
    padding: 6px 4px 0px 6px;
    font-size: 1rem;
    font-weight: 700;
    color: #666666;
}

.record-summary {
    width: 100%;
    margin: 0 auto;
    padding: 4px 4px 0px 6px;
    color: #999;
    box-sizing: border-box;
    // height: 42px;
    font-size: 0.9rem;

    .row {
        padding: 0;
    }
}

// .record-summary-col {
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     overflow: hidden;            
// }

.record-summary-id {
    padding-left: 6px;
}

// .rs-label {
//     display: inline-block;
//     font-weight: bold;
//     margin-right: 4px;
//     width: 70px;
// }

.pt-header-status-message { 
    font-size: 0.9rem;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    padding: 1px 0 3px 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}

.pt-header-status-error {
    color: #fff;
    background-color: rgb(255, 0, 0);
    margin: 2px 0 0 0;
}

.pt-header-status-saved {
    color: rgb(0, 0, 0);
    background-color: rgb(51, 255, 0);
    margin: 2px 0 0 0;
}
// icon in editor header
.pt-editor-icon {
    font-size: 140%;
}