
.nav-tabs .nav-link {
    cursor: pointer; 
}
.pt-tabs-bckgrnd {
    background-color: $toolbar_bg2;
    // border-bottom: none;
    // border-bottom: 1px solid rgb(199, 199, 199) !important;
    padding-top: 10px !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    
    &.nav-tabs {
        // border-bottom: 2px solid rgb(255, 255, 255) !important;
    }

    a.nav-link {
        // border-bottom: 2px solid rgb(255, 255, 255) !important;
    }
    a {
        border-top: 4px solid $toolbar_bg2 !important;
        
        &:hover {
            text-decoration: underline dotted!important;
            background-color: $blue;
            border-color: $blue !important;
            border-top: 4px solid $blue !important;
            color: #fff !important;
            }
        &.active {
            text-decoration: none !important;
            cursor: default !important;
            border-top: 4px solid $orange !important;
            border-left: 1px solid $pt-form-border !important;
            border-right: 1px solid $pt-form-border !important;
            color: #333 !important;
            border-bottom: 1px solid #fff !important;
        }
    }
}

.pt-editor-tab {
    padding-top: 10px !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    

    a {
        border-top: 4px solid #fff !important;
        &:hover {
            text-decoration: underline dotted !important;
            background-color: $blue;
            border-color: $blue !important;
            border-top: 4px solid $blue !important;
            color: #fff !important;
            }
        &.active {
            text-decoration: none !important;
            cursor: default !important;
            border-top: 4px solid $orange !important;
            border-left: 1px solid $pt-form-border !important;
            border-right: 1px solid $pt-form-border !important;
            color: #333 !important;
            border-bottom: 1px solid #fff !important;
        }
    }
}

.pt-editor-tab-pane {
    border-left: 1px solid $pt-form-border !important;
    
}