body, html {
	font-size: 12px;
	height: 100%;
	scroll-behavior: smooth;
	// overflow: hidden;
}

.blue {
  color: $blue;
}

.h-0 {
  height: 0;
}

.w-0 {
	width: 0 !important;
}

.rotate--90 {
  -webkit-transform: rotate(-90deg);  
      -ms-transform: rotate(-90deg);  
          transform: rotate(-90deg);
}

.content-wrapper {
  padding: 0 1rem;
}

.instruction {
	display: block;
	font-size: 0.8rem;
	opacity: 0.8;
}

label {
	font-weight: bold;
}

.btn {
	border-radius: 0px !important;
}

.btn-primary {
	&:hover {
		background-color: darken($blue, 10%);
	}
}

.btn-secondary {
	color: $white !important;

	&:hover {
		background-color: #939393;
		border-color: #939393;
	}
}

.btn-outline-secondary {
	border-color: #b0b0b0 !important;
}

.overflow-scroll {
    overflow-x: scroll;
}

.accordion-svg {
	max-width: 20px;
	display: inline-block;
	margin-right: 6px;

	svg {
		opacity: 0.5;
	}
}

.react-bootstrap-table {
	height: 100%;
	overflow-y: scroll;
}

.main-container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.tab-content {
	border-left: 1px solid #cecece;
	border-right: 1px solid #cecece;
	border-bottom: 1px solid #cecece;
}

.pt-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.pt-list-buttons {
	.dropdown {
		display: inline;
		padding-left: 8px;
	}
}

.pt-seperator {
	border-top: dotted 2px #999;
	padding-top: 20px;
	padding-bottom: 20px;
	// margin-top: 8px;
}

.pt-map-badge {
	border-radius: 20rem !important;
	padding: 5px;
	background-color: #ea4335 !important;
}