.table {

    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    // .form-control {
	// 	display: block;
	// 	width: 100%;
	// 	height: calc(1.5em + 0.75rem + 2px);
	// 	padding: 0.375rem 0.75rem;
	// 	font-size: 1rem;
	// 	font-weight: 400;
	// 	line-height: 1.5;
	// 	color: #495057;
	// 	background-color:#fff;
	// 	background-clip: padding-box;
	// 	border: 1px solid #ced4da !important;
	// 	border-radius: 0.25rem;
	// 	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;	
	// }
    // select {
    //     border: none !important;
    // }

}
.table th, .table td {
    padding: 0.4rem;
}
.react-bootstrap-table {
    overflow-y: initial !important;
        th { 
            background-color: rgb(247, 247, 247);
            // background-image: linear-gradient(rgb(247, 247, 247), rgb(226, 226, 226));
            white-space: pre-wrap;
            overflow: hidden;
            font-size: 90% !important;
            text-overflow: ellipsis; 
        }
        .filter-label {
            border-radius: 4px !important;
            // border: 1px solid rgb(211, 211, 211) !important;
            padding: 1px;

        }

        td {
            white-space: pre-wrap;
            overflow: hidden;
            text-overflow: ellipsis; 
        }
    
        tr {
            cursor: pointer;
            &:hover {
                background-color: $orange-lite !important;
            }
            // &.selected {
            //     background-color: $orange !important;
            // }

            &.pt-selected-row {
                background-color: $orange !important;
                &:hover {
                    background-color: $orange !important;
                }
            }  
            //  might not be needed if expanded table row doesnt need a different active colour
            // &.pt-rpt-row-selected {
            //     background-color: $glow !important;
            //     &:hover {
            //         background-color: $glow !important;
            //     }
            // }          
        }
    
}


.pt-table-hscroll {
    overflow-x: scroll;
}

.pt-items-total-number{
    margin-top:0px;
    margin-bottom:0px;
    padding: 0px 6px 6px 6px;
    border: 0px;
    background-color: #fff !important;
    color: $pt-med-grey !important;
    font-weight: 600;
}