.summary-container {
    border: 1px solid #FFFFFF;
    padding: 2px;
    background-color: #fff2ad;
    span {
        border-right: 1px solid #FFFFFF;
        padding: 2px 4px;
        &:last-child {
            border-right: none;
        }
    }
}