.editor-container {
   // position: absolute;
    top: 0;
    bottom: 0;
    background-color: $toolbar_bg;
    width: 100%;
    height: 100%;
    margin-bottom: 40px;    
}

.editor-panel {
    padding: 4px 10px;
    background-color: #ffffff;
    overflow: hidden;
    // background-color: rgb(201, 190, 204) !important;
}

.editor-section {
    margin-top: 10px;

    h3 {
        font-size: 1.3rem;
        margin-bottom: 0;
        font-weight: bold;
        color: #666;
    }

    h4 {
        font-size: 1.1rem;
        margin-bottom: 0;
        font-weight: bold;
        color: #666;
    }

}
// day schedule styles for inside accordions on main editor

.pt-event-sections {

    // background-color: $toolbar_bg;
    width: 100%;
  
    // margin-left: 0px !important;
    border-top: 1px solid rgb(206, 206, 206);
    h5 {
        font-size: 1.1rem;
        margin-bottom: 0;
        font-weight: bold;
        color: #666;
    }
}


// if a non-project person has been added to an event this class is used to highlight them in the list
.people-additional {
    background-color: #ffffff;
    color: $blue !important;
    padding-left: 8px !important;
    padding-right: 6px !important;  
    font-size: 1rem;
}

.people-lead {
    background-color: #ffb833 !important;
    color: rgb(61, 61, 61);
    padding-left: 8px !important;
    padding-right: 6px !important;  
    font-size: 1rem;
     
    &:hover{
        color: #fff;
    }
}

.people-tour-manager {
    // background-color: #5f6063 !important;
    background-color: #ffb833 !important;
    color: rgb(61, 61, 61);
    padding-left: 8px !important;
    padding-right: 6px !important;  
    font-size: 1rem;
    border-top: solid 2px #ffffff !important;
    border-bottom: solid 2px #ffffff !important;

    button {
        // color: rgb(255, 255, 255) !important;  
        color: rgb(61, 61, 61) !important;

    }
    &:hover{
        color: #fff;
    }
}

// projects company as set up in admin
.pt-p-company {
    .list-group-item {
        background-color: $pt-mid-grey;  
    }
    
}
.pt-p-company-plus-additional {
    .project-person {
        .list-group-item {
            background-color: $pt-mid-grey;
        }

    }
    .people-additional {
        .list-group-item {
            background-color: #ffffff; 
        }
        
    }
}

// in the people selector - this class is added to people in the list who are
// a part of the project if the people selector is triggered from an event
.project-person {
    background-color: $pt-mid-grey;
    color: $blue !important;
    padding-left: 8px !important;
    padding-right: 6px !important;  
    font-size: 1rem;
}

.pt-recurs{
    border-top: 1px solid rgb(187, 187, 187);
    // margin: 0px 4px 0px 4px;
    padding-top: 10px;

}
