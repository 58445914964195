.task-h {
    margin: 14px 0;
    padding-bottom: 6px;
    font-size:1.2rem;
    font-weight: bold;
    border-bottom: 1px solid #ffffff;
}

.App {
    font-family: sans-serif;
    text-align: center;
}

// forces the <strong> element to be 100% wide so that we can right align the validation inidcator
.task-toast-h {
    strong {
        width: 100%;
    }
}
