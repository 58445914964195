// .feedback-container {
//     background-color: $toolbar_bg;
//     padding: 0 10px;
// }

// .feedback-editor {
//     border-left: 1px solid #dee2e6;
//     // padding: 10px 10px 10px 12px;
//     margin: 0;
//     background-color: #fff;
// }

.feedback-summary {
    label {
        font-weight: bold;
    }

}