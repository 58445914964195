.booking-editor {
    background-color: $toolbar_bg;
    
}

.booking-editor-top {
    padding: 6px;
    box-sizing: border-box;
}

.booking-editor-tab {
    background-color: #fff;
    padding: 6px;
    box-sizing: border-box;
    // border-left: 1px solid rgba(0,0,0,.125);
    // border-right: 1px solid rgba(0,0,0,.125);
    // border-bottom: 1px solid rgba(0,0,0,.125);
    

}

.card-header {
    .btn {
        font-size: 1.2rem;
    }
}
.pt-editor-toast {
    max-width: 100% !important;
    background-color:#f5ffe2 !important;
    .toast-header {

        background-color:#d0ff79 !important;
    }
}
.event-list-table {
    margin-bottom: 0px !important;
}
.pt-ttbc {
        font-size: 120% !important;
        color: #666;
}

.event-list-row {
    cursor: pointer;   
    background-color: $toolbar_bg;
    &:hover {
        background-color: $orange-lite !important;
    }

    &.selected {
        background-color: $orange !important;
    }
}

.booking-editor-event-tab {
    background-color: $toolbar_bg;
    padding-top: 10px !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    

    a {
        border-top: 4px solid $toolbar_bg !important;
        font-size: 0.9em !important;
        &:hover {
            text-decoration: underline dotted!important;
            background-color: $blue;
            border-color: $blue !important;
            color: #fff !important;
            
            }
        &.active {
            text-decoration: none !important;
            cursor: default !important;
            border-color: #fff !important;
            border-top: 4px solid $orange !important;
            border-left: 1px solid $pt-form-border !important;
            border-right: 1px solid $pt-form-border !important;
            border-bottom: 1px solid #fff !important;
            color: #333 !important;
        }
    }
}

.booking-event-filter-on {
    background-color: #f4e0fd;
       
}
.booking-event-filter-on-label {

    color: #973395;
       
}
.pt-alert2-icon-lg{
    font-size: 100%;
    padding:2px !important;
    color: $warning !important;
    text-shadow: -1px 1px 0 #f36201, 1px 1px 0 #f36201, 1px -1px 0 #f36201, -1px -1px 0 #f36201;
  }