// icon in date field
.pt-datepicker-wrapper { 
    position: relative; 
    width: 100px;
}
.pt-dateInput {
    padding-left: 20px;
    border: 1px solid #ced4da;
    width: 100px;
}
i.icon-calendar1 {
    position: absolute; top: 5px; left: 5px; 
    color: #666;
}
// icon in time field
.pt-timepicker-wrapper { 
    position: relative; 
    width: 80px;
}
.pt-timeInput {
    padding-left: 20px;
    border: 1px solid #ced4da;
    width: 80px;
}
i.icon-clock {
     position: absolute; top: 5px; left: 5px; 
     color: #666;
}

// percentage symbol in number fields
.pt-percentage-wrapper {
    position: relative;

    i {
        color: rgb(105, 105, 105);
        position: absolute; top: 9px; right: 5px;
    }
}
.pt-percentageInput {
    padding-right: 18px !important;

    border: 1px solid #ced4da;

    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    // padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color:
    #495057;
    background-color:
    #fff;
    background-clip: padding-box;
    border: 1px solid
    #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    overflow: visible;
    margin: 0;
    font-family: inherit;
}

.pt-percentage-display {
    display: inline-block;
    margin-right: 4px;
}

// currency symbol in money fields
.pt-currency-wrapper { 
    position: relative;

    i {
        color: #333;
        position: absolute; top: 8px; left: 5px;
    }
}
.pt-currencyInput {
    padding-left: 18px;
    border: 1px solid #ced4da;

    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    // padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color:
    #495057;
    background-color:
    #fff;
    background-clip: padding-box;
    border: 1px solid
    #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    overflow: visible;
    margin: 0;
    font-family: inherit;
}

.pt-currency-display {
    display: inline-block;
    margin-left: 4px;
}
