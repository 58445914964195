.toolbar {
    width: 100%;
    margin: 0;
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    background-color: $toolbar_bg2;
    box-sizing: border-box;
    height: 46px;

    .btn {
        font-size: 1rem !important;
    }
}