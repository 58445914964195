.collapse.show {
  display: block;
}

.booking-info-panel {
  .btn {
    width: 100%;
  }

  .accordion {
    border: 1px solid gray;
    border-radius: 4px;
    list-style: none;
    overflow: hidden;
    padding-left: 0;

    > li {
      border-top: 1px solid gray;

      > .btn {
        border-radius: unset;
        color: $blue;
        padding-left: 2rem;
        text-align: left;

        &:focus {
          box-shadow: none;
        }
        
      }

      // .collapse,
      // .collapse.show,
      // .collapsing {
      //   transition: height 1s ease-in-out !important;
      // }

      .content-wrapper {
        padding: 1rem 2rem;
      }

      .collapsing,
      .collapse.show {
        border-top: 1px solid gray;
      }

      &:first-child {
        border-top: unset;
      }
    }
  }
}
