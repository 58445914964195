.contact-name-h {
	margin: 6px 0 14px 0;
}

.categories-list {
	list-style: none;
	padding-left: 1rem;

	> li {
		padding: 0;
		margin: 0;
	}
}

.contact-tab {
	background-color: #fff;
	// border-left: 1px solid #dee2e6;
	// padding: 20px;
}

.contact-people-container {
	padding: 0px;
	background-color: #fff;

	.card {
		margin-bottom: 10px;
		background-color: $pt-light-grey;
	}
}
.contact-display-container {
	padding: 0px;
	background-color: #fff;

}
.pt-viewer-card {
	padding: 20px;
	margin-bottom: 20px;
	margin-right: 10px;
	margin-left: 10px;
	background-color: $pt-light-grey;
}
.contacts-search-list-row {
    background-color: $toolbar_bg;
}
.pt-modal-notes{
	border: 1px solid rgb(189, 189, 189);
	background-color: $toolbar_bg;
}
.pt-modal-notes-alt{
	border: 1px solid rgb(189, 189, 189);
	background-color: rgb(226, 226, 226);
}

.contact-comms-table {

	textarea.form-control {
		height: calc(1.5em + 0.75rem + 2px);
		
	}
	td {
		vertical-align: middle !important;
		padding-right: 2px;
		padding-left: 2px;
	}

	tr.private {
		background-color: #ffb79f;
		&:hover {
			background-color: #ec9375;
		}
	}
	// .invalid-feedback {
	// 	position: relative; top: -70px; left: 5px; 
	// }
}

// Comms category badges
.commcat-badge {
	display: block !important;
	margin-bottom: 4px;
	padding: 2px !important;
}

.commcat-badge-lg {
	@extend .commcat-badge;
	padding: 6px !important;
}

// Organisational category badges

.orgcat-badge {
	padding: 4px 4px 6px 4px !important;
	margin-right: 4px;
}

// contact viewer styles
.cv-label {
	display: block;
	font-weight: 700;
}

.cv-title {
	div {
		width: 100%
	}
}