.travel {
    border-bottom: 4px solid #b09bdb;
    background-color: #d7cbf0;
  }

  .accomm {
    border-bottom: 4px solid #f0a6d6;
    background-color:#ffd7f1;
    table.comms-table {
      margin-top: 10px !important;
      th {
        border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
        border-bottom: 2px solid rgba(0, 0, 0, 0.3) !important;
      }
      a {
        // color: #720563;
        color: #1a53cf;
        font-weight: 700;
      }
    }
  }

  .tech {
    border-bottom: 4px solid #bdab98;
    background-color:#d7cfc6;
  }

  .reh {
    border-bottom: 4px solid #dfb561;
    background-color: #ffe9be;
  }

  .press {
    border-bottom: 4px solid #61d3ba;
    background-color:#b8fff4;
  }

  .photo {
    border-bottom: 4px solid #83e695;
    background-color: #d1ffd9;
  }
  .patron {
    border-bottom: 4px solid #39b9f5;
    background-color: #c4f1ff;
  }
  // temporary style for day marker ( old misc style )
  .day {
    background-color: rgb(255, 253, 223);
    border: 0.3rem dashed rgb(139, 139, 139);
  }

  .blocking {
    height: 100%;
    min-height: 200px;
    /* On "top" */
    border: 0.3rem dashed rgb(236, 63, 121);
    // background: repeating-linear-gradient(
    //   135deg,
    //   transparent,
    //   transparent 6px,
    //   #ccc 12px,
    //   #ccc 10px
    // ),
    $lightColor: rgba(241, 241, 241, 0.589);
    $stripWidth: 12px;
    background-color: rgb(209, 209, 209);
    background-image: 
      repeating-linear-gradient(135deg, 
        transparent,
        transparent $stripWidth, 
        $lightColor $stripWidth, 
        $lightColor ($stripWidth * 2)
      );
    /* on "bottom" */
    // linear-gradient(
    //   to bottom,
    //   #eee,
    //   #999
    // ); 
  }