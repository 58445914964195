.footer {
    background-color:#d3d3d3;
    border-top: 1px solid #999;
    box-sizing: border-box;
    color: rgb(56, 55, 55);
    width: 100%;
    padding: 4px;
    z-index: 3000;
}

.pt-logo-footer {
    height: 12px;
    margin: 0px 4px 2px 4px;
}
