.pt-header {
  margin-bottom: 0;
  background-color: $pt-header;
  padding: 0 !important;
  width: 100%;
  .navbar-collapse {
      @include media-breakpoint-up(md) {
        margin-left: 4px;
        height: 100%;
    }
  }
}

.pt-nav {
    background-color: #b0b0b0;
    a {
        color: #fff;
        &.active {
          cursor: default;
          background-color: $pt-nav !important;
          color: #666 !important;
        }
        &:hover {
          background-color: $blue;
          color: #fff;
        }
    }
    @include media-breakpoint-up(md) {
        height: 100%;
        a {
            padding: 10px 14px;
            font-size: 1rem;
            text-transform: uppercase;
            height: 100%;
        }
    }
}

.navbar-collapse.collapse.show {
  display: block;
}

.navbar .logged-in {
    position: absolute;
    top: 0;
    right: 0;
    @include media-breakpoint-up(md) {
        position: unset;
        margin-right: 0;
        .logged-in-user {
            margin-right: 0;
            padding-right: 0 !important;
        }
    }
}

.navbar-nav .nav-item+.nav-item {
  margin-left: 0;
}

.logo {
  max-width: 50px;
}

.navigation {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 0;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-right: auto;
  position: relative;
  width: 100%;
}

@media (min-width: 1024px) {
  .navbar-toggler {
    display: none;
  }

  .navbar-collapse {
    display: inline-block !important;
    flex: auto !important;
    margin-left: 1rem;
  }

  .navbar-nav {
    flex-direction: row !important;

   .nav-item+.nav-item {
      margin-left: 1rem;
    }
  }
}


.pt-logo-header {
  // height: 16px; 
  // width: 16px;
  margin: 0px 0px 0px 4px;
}